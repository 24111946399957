<template>
  <div class="status-container"
  >
    <span class="status-txt">{{ state.autoReconnect ? printerStatus : 'Disabled' }}</span>

    <template v-if="!state.autoReconnect">
      <div
        :class="['printer-status-icon']"
        @click="toggleAutoReconnect"
      >
        <i
          class="icon-block"
          :style="{color: state.color}"
        />
      </div>
    </template>

    <template v-else-if="state.autoReconnect && printerStatus !== 'connecting'">
      <div
        :class="['printer-status-icon']"
        @click="toggleAutoReconnect"
      >
        <i
          :class="state.icon"
          :style="{color: state.color}"
        />
      </div>
    </template>

    <template v-else>
      <Spinner size="25" thickness="10" />
    </template>

  </div>
</template>

<script>
import { computed, defineComponent, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import Printer from '@/utils/Printer';
import Spinner from '@/components/Util/Spinner';
import { notify } from '@/Mixins/appHelper';

export default defineComponent({
  name: 'PrinterStatus',
  components: { Spinner },
  setup () {
    const store = useStore();

    const icons = {
      [Printer.STATUS_CONNECTING]: '',
      [Printer.STATUS_CONNECTED]: 'icon-print',
      [Printer.STATUS_DISCONNECTED]: 'icon-printer',
      [Printer.STATUS_FAILED]: 'icon-printer',
    };
    const printerStatus = computed(() => store.getters['print/getStatus']);

    const state = reactive({
      autoReconnect: computed(() => store.getters['print/getAutoReconnect']),
      icon: icons[Printer.STATUS_DISCONNECTED],
      color: '#000',
    });

    watch(() => printerStatus.value, (nv) => {
      state.icon = getStatusIcon(nv);
    }, { immediate: true });

    watch(() => state.autoReconnect, (nv) => {
      const action = nv ? 'Enabled' : 'Disabled';
      const type = nv ? 'success' : 'warning';
      notify(`Printing ${action}`, type);
    });

    function getStatusIcon (status) {

      switch (status) {
        case Printer.STATUS_CONNECTING:
          return icons[Printer.STATUS_CONNECTING];

        case Printer.STATUS_CONNECTED:
          state.color = '#000';
          return icons[Printer.STATUS_CONNECTED];

        case Printer.STATUS_FAILED:
          state.color = '#c02929';
          return icons[Printer.STATUS_FAILED];

        default:
          state.color = '#000';
          return icons[Printer.STATUS_DISCONNECTED]
      }

    }

    function toggleAutoReconnect () {
      store.dispatch('print/setAutoReconnect', !state.autoReconnect);
    }

    return {
      state,
      printerStatus,
      toggleAutoReconnect,
    };
  }
});
</script>

<style scoped lang="scss">
@import "../../assets/sass/_variables.scss";
.status-container {
  display: flex;
  align-items: center;
  margin-right: 10px;

  .status-txt {
    color: $title-color;
    margin-right: 5px;
    text-transform: uppercase;
  }

  .printer-status-icon {
    color: $sub-title-color !important;
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }
}
</style>
