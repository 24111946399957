import http from '@/Plugins/http';
import Api from '@/Services/Api';
import { DELETE_ORDER, GET_ORDERS, PRINT_ORDER, UPDATE_ORDER, UPDATE_PRINT_STATUS } from '@/utils/Urls';

export default class Order {

  static getOrder = () => Api.getResponse(() => http.get(GET_ORDERS));

  static update = (id, data) => Api.getResponse(() => http.patch(`${UPDATE_ORDER}/${id}`, data));

  static updatePrintStatus = (id, data) => Api.getResponse(() => http.patch(`${UPDATE_PRINT_STATUS}${id}`, data));

  static print = (id) => Api.getResponse(() => http.post(`${PRINT_ORDER}/${id}`));

  static destroy = (id) => Api.getResponse(() => http.delete(`${DELETE_ORDER}/${id}`));
}
