<template>
  <ConfirmDialog />
  <router-view/>
  <PrintHandler />
</template>

<script>
import ConfirmDialog from 'primevue/confirmdialog';
import PrintHandler from '@/components/Util/PrintHandler';
export default {
  name: 'app',
  components: { PrintHandler, ConfirmDialog }
}
</script>
