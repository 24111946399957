import PrintFormatter from '@/utils/Printer/PrintFormatter';

export default class PrintProcessor {

  printer = null;
  printData = [];

  constructor (printer, printData) {
    this.printer = printer;
    this.printData = printData;
  }

  printNow () {

    const formatter = new PrintFormatter(this.printer);

    // add all content
    this.printData.forEach((lineData) => {

      const parsedData = this._parseLine(lineData);
      this._addItem(parsedData, formatter);

    });

    // add cutting
    formatter.setCut();

    // start for printing
    formatter.startPrint();
  }

  _parseLine (lineData) {
    const type = lineData.t;
    const options = lineData.f;
    const content = lineData.c;

    const [font, fHeight, fWidth, bold, align, newLine, lineHeight] = options.split(',');

    return {
      type,
      options: {
        font: parseInt(font),
        fWidth: parseInt(fWidth),
        fHeight: parseInt(fHeight),
        bold: !!(parseInt(bold)),
        align,
        newLine: !!(parseInt(newLine)),
        lineHeight: parseInt(lineHeight)
      },
      content
    };
  }

  _addItem (parsedData, formatter) {
    // apply styling
    formatter
      .setFont(parsedData.options.font)
      .setSize(parsedData.options.fWidth, parsedData.options.fHeight)
      .setBold(parsedData.options.bold)
      .setAlign(parsedData.options.align)
      .setLineHeight(parsedData.options.lineHeight)

      // add actual text
      .addText(parsedData.content, parsedData.options.newLine)
    ;
  }


}
