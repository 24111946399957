<template>
  <nav
    id="sidebar"
    class="sidebar-wrapper"
    @mouseenter="handleHover(true)"
    @mouseleave="handleHover(false)"
    @click.capture="handleClickToExpand"
  >

    <!-- Sidebar brand start  -->
    <div class="sidebar-brand d-flex justify-content-center">
      <router-link to="/" class="logo">{{ state.appName }}</router-link>
    </div>
    <!-- Sidebar brand end  -->

    <!-- Quick links start -->
    <div class="quick-links-container">
      <h6>Quick Actions</h6>
      <div class="quick-links">

        <router-link
          :to="{name: routeMap.order.name}"
          class="bg-primary new-badge-container"
          data-toggle="tooltip"
          data-placement="top"
          title="Orders"
          data-original-title="Orders"
          :data-link-type="quickButtonLinkTypeText"
        >
          <span
            v-if="state.newOrdersCount"
            class="new-badge"
            :data-link-type="quickButtonLinkTypeText"
          >
            {{ state.newOrdersCount }}
          </span>
          <i class="icon-check-circle" :data-link-type="quickButtonLinkTypeText" />
        </router-link>

        <router-link
          :to="{name: routeMap.reservation.name}"
          class="bg-primary new-badge-container"
          data-toggle="tooltip"
          data-placement="top"
          title="Reservation"
          data-original-title="Reservation"
          :data-link-type="quickButtonLinkTypeText"
        >
          <span
            v-if="state.newReservationsCount"
            class="new-badge"
            :data-link-type="quickButtonLinkTypeText"
          >
            {{ state.newReservationsCount }}
          </span>
          <i class="icon-event_available" :data-link-type="quickButtonLinkTypeText" />
        </router-link>

        <a
          href=""
          class="bg-grey"
          data-toggle="tooltip"
          data-placement="top"
          title="Refresh"
          data-original-title="Refresh"
          :data-link-type="quickButtonLinkTypeText"
          @click.prevent="refreshPage"
        >
          <i class="icon-rotate-cw" :data-link-type="quickButtonLinkTypeText" />
        </a>

        <a
          class="bg-danger"
          data-toggle="tooltip"
          data-placement="top"
          title="Logout"
          @click="handleLogout"
          :data-link-type="quickButtonLinkTypeText"
        >
          <spinner
            v-if="state.loading"
            type="li"
            size="20"
            :data-link-type="quickButtonLinkTypeText"
          />
          <i v-else class="icon-power1" :data-link-type="quickButtonLinkTypeText" />
        </a>

      </div>
    </div>
    <!-- Quick links end -->

    <!-- Sidebar content start -->
    <div
      class="sidebar-content"
      :style="{
        height: state.contentHeight
      }"
    >

      <!-- sidebar menu start -->
      <div class="sidebar-menu">
        <ul>

          <MenuItem
            v-if="isAuthorized(routeMap.dashboard.permission)"
            icon="icon-home2"
            :route-name="routeMap.dashboard.name"
          />

          <MenuItem
            v-if="isAuthorized(routeMap.order.permission)"
            icon="icon-assignment"
            :route-name="routeMap.order.name"
          />

          <MenuItem
            v-if="isAuthorized(routeMap.orderMenu.permission)"
            icon="icon-import_contacts"
            :route-name="routeMap.orderMenu.name"
            :title="routeMap.orderMenu.title"
          />

          <MenuItem
            v-if="isAuthorized(routeMap.reservation.permission)"
            icon="icon-receipt"
            :route-name="routeMap.reservation.name"
          />

          <MenuItemGroup
            v-if="isAuthorized(permissionsGroup.menu)"
            title="Menu"
            icon="icon-layers2"
            :routes="[
              routeMap.menuCategories,
              routeMap.menuItems,
              routeMap.menuAddons,
              routeMap.menuSetMenu
            ]"
          />


          <MenuItem
            v-if="isAuthorized(routeMap.opening.permission)"
            icon="icon-circular-graph"
            :route-name="routeMap.opening.name"
            :title="routeMap.opening.title"
          />

          <MenuItem
            v-if="isAuthorized(routeMap.offer.permission)"
            icon="icon-price-tag"
            :route-name="routeMap.offer.name"
            :title="routeMap.offer.title"
          />

          <MenuItem
            icon="icon-people"
            v-if="isAuthorized(routeMap.customers.permission)"
            :route-name="routeMap.customers.name"
            :title="routeMap.customers.title"
          />

          <MenuItem
            v-if="isAuthorized(routeMap.deliveryCharge.permission)"
            icon="icon-local_shipping"
            :route-name="routeMap.deliveryCharge.name"
            :title="routeMap.deliveryCharge.title"
          />

          <MenuItem
            icon="icon-globe"
            v-if="isAuthorized(routeMap.seo.permission)"
            :route-name="routeMap.seo.name"
            :title="routeMap.seo.title"
          />

          <MenuItem
            icon="icon-airplay"
            v-if="isAuthorized(routeMap.inactivityAd.permission)"
            :route-name="routeMap.inactivityAd.name"
            :title="routeMap.inactivityAd.title"
          />

          <MenuItemGroup
            v-if="isAuthorized(permissionsGroup.acl)"
            title="Access Control"
            icon="icon-lock-open"
            :routes="[
              routeMap.aclUsers,
              routeMap.aclGroups,
              routeMap.aclPermissions
            ]"
          />

          <MenuItem
            v-if="isAuthorized(routeMap.settings.permission)"
            icon="icon-settings"
            :route-name="routeMap.settings.name"
            :title="routeMap.settings.title"
          />

        </ul>
      </div>
      <!-- sidebar menu end -->

    </div>
    <!-- Sidebar content end -->

    <div
      v-if="!isMobile"
      class="sidebar-footer"
    >
      <div class="card">
        <div class="upgrade-account">
          <h5>Upgrade your account</h5>
          <!--          <h6>Upgrade to PRO to earn more<br>revenue &amp; get benefits.</h6>-->
          <div class="custom-btn-group mt-2">
            <a href="#" class="btn btn-success ml-0"><i class="icon-trending-up"></i>Upgrade</a>
          </div>
        </div>
      </div>
    </div>

  </nav>
</template>

<script>
import { useStore } from 'vuex';
import Spinner from '@/components/Util/Spinner';
import { computed, reactive, watch } from 'vue';
import { appReload, isAuthorized, routeIs } from '@/utils/Helper';
import MenuItem from '@/components/Util/LeftSidebar/MenuItem';
import MenuItemGroup from '@/components/Util/LeftSidebar/MenuItemGroup';
import { permissionsGroup } from '@/utils/permissions';
import { isMobile } from 'mobile-device-detect';

export default {
  name: 'Sidebar',
  components: { Spinner, MenuItem, MenuItemGroup },
  setup: function () {
    const store = useStore();
    const userState = store.state.user;
    const appState = store.state.app;

    const state = reactive({
      loading: false,
      appName: computed(() => store.getters['setting/getSettings']('app_name')),

      newOrdersCount: computed(() => store.getters['app/getNewOrdersCount']),
      newReservationsCount: computed(() => store.getters['app/getNewReservationCount']),

      contentHeight: isMobile ? 'calc(100% - 215px) !important' : 'inherit',
    });

    // update state
    watch(userState, (nv) => {
      state.loading = nv.loading;
    });

    const handleHover = isHovering => {

      // hover is only effective when not expanded
      if (!store.state.app.layout.sidebar.expanded) {
        setTimeout(() => {
          store.dispatch('app/setSidebarHover', isHovering);
        }, 50);
      }
    };

    const handleLogout = () => {
      store.dispatch('user/tryLogOut');
    };

    const quickButtonLinkTypeText = 'quick_link';

    const handleClickToExpand = (e) => {

      // only work on mobile
      if (!isMobile) return;

      const hoverState = appState.layout.sidebar.hovered;
      const expandedState = appState.layout.sidebar.expanded;
      const targetIsQuickLink = (e.target.dataset.linkType === quickButtonLinkTypeText);

      // prevent click when
      // 1. menu is collapsed
      // 2. target is not type of quick link
      if (!hoverState && !targetIsQuickLink && !expandedState) {
        e.preventDefault();
      }
    };

    const refreshPage = () => {
      if (window.JSInterface) {
        window.JSInterface.handleAppReload();
        return;
      }

      window.location.reload();
    };

    return {
      state,
      handleHover,
      handleLogout,
      handleClickToExpand,
      routeIs,
      isAuthorized,
      isMobile,
      permissionsGroup,
      quickButtonLinkTypeText,
      refreshPage,
    };
  }
};
</script>

<style lang="scss">
.sidebar {
  &-content {
    // height: 100%;
  }

  &-menu {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &-footer {
    height: 300px;
    width: 100%;
    margin-top: -30px;
    //height: 300px;
    //position: absolute;
    //bottom: 0px;
    //left: 0;
    // margin-top: -120px;
    //position: fixed;
    //bottom: 0;
    //left: 0;
    padding: 20px;
  }
}
</style>

<style scoped lang="scss">
@import "../../assets/sass/variables";
a.router-link-active.router-link-exact-active.logo {
  background: transparent !important;
  color: $title-color !important;
}
.new-badge-container {
  position: relative;

  .new-badge {
    position: absolute;
    top: -10px;
    left: -7px;
    background-color: $secondary2;
    border: 1px solid #f5f6fa;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
}
</style>
