export const BASE_API_URL = process.env.VUE_APP_BASE_URL_API;

export const LOGIN_URL = 'api/auth/login';
export const LOGOUT_URL = 'api/auth/logout';
export const AUTH_USER = 'api/user';

// Dashboard Content
export const GET_DASHBOARD_CONTENT = 'api/admin';

// WS
export const WS_AUTHORIZATION = 'api/broadcasting/auth';

// Orders
export const GET_ORDERS = 'api/admin/order';
export const UPDATE_ORDER = 'api/admin/order';
export const UPDATE_PRINT_STATUS = 'api/admin/order/print-status/';
export const PRINT_ORDER = 'api/admin/order/print';
export const DELETE_ORDER = 'api/admin/order';

// reservations
export const GET_RESERVATIONS = 'api/admin/reservation';
export const UPDATE_RESERVATION = 'api/admin/reservation';
export const DELETE_RESERVATION = 'api/admin/reservation';

// opening hour

export const GET_OPENING_HOURS = 'api/admin/opening';
export const CREATE_OPENING_HOURS = 'api/admin/opening';
export const UPDATE_OPENING_HOURS = 'api/admin/opening';
export const DELETE_OPENING_HOURS = 'api/admin/opening';

// menu |> //
// categories
export const GET_MENU_CATEGORIES = 'api/admin/menu/categories';
export const CREATE_MENU_CATEGORY = 'api/admin/menu/categories';
export const UPDATE_MENU_CATEGORY = 'api/admin/menu/categories/';
export const DELETE_MENU_CATEGORY = 'api/admin/menu/categories/';

// items
export const GET_MENU_ITEMS = 'api/admin/menu/items';
export const CREATE_MENU_ITEM = 'api/admin/menu/items';
export const UPDATE_MENU_ITEM = 'api/admin/menu/items/';
export const DELETE_MENU_ITEM = 'api/admin/menu/items/';

// addons
export const GET_MENU_ADDONS = 'api/admin/menu/addons';
export const CREATE_MENU_ADDON = 'api/admin/menu/addons';
export const UPDATE_MENU_ADDON = 'api/admin/menu/addons/';
export const DELETE_MENU_ADDON = 'api/admin/menu/addons/';

// set menu
export const GET_SET_MENUS = 'api/admin/menu/set-menus';
export const CREATE_SET_MENU = 'api/admin/menu/set-menus';
export const UPDATE_SET_MENU = 'api/admin/menu/set-menus/';
export const DELETE_SET_MENU = 'api/admin/menu/set-menus/';
// menu <| //

// Seo Meta
export const GET_SEO_META = 'api/admin/seo-meta';
export const CREATE_SEO_META = 'api/admin/seo-meta';
export const UPDATE_SEO_META = 'api/admin/seo-meta';
export const DELETE_SEO_META = 'api/admin/seo-meta';

// Offer
export const GET_OFFER = 'api/admin/offer';
export const CREATE_OFFER = 'api/admin/offer';
export const UPDATE_OFFER = 'api/admin/offer';
export const DELETE_OFFER = 'api/admin/offer';

// Customers
export const GET_CUSTOMERS = 'api/admin/customers';

// menu |> //
// users
export const GET_ACL_USERS = 'api/admin/acl/users';
export const UPDATE_ACL_USER = 'api/admin/acl/users/';

//groups
export const GET_ACL_GROUPS = 'api/admin/acl/groups';
export const CREATE_ACL_GROUP = 'api/admin/acl/groups';
export const UPDATE_ACL_GROUP = 'api/admin/acl/groups/';
export const DELETE_ACL_GROUP = 'api/admin/acl/groups/';

//permissions
export const GET_ACL_PERMISSIONS = 'api/admin/acl/permissions';
// menu <| //

// Delivery Charges
export const GET_DELIVERY_CHARGES = 'api/admin/delivery-charge';
export const CREATE_DELIVERY_CHARGES = 'api/admin/delivery-charge';
export const UPDATE_DELIVERY_CHARGES = 'api/admin/delivery-charge';
export const DELETE_DELIVERY_CHARGES = 'api/admin/delivery-charge';

// settings
export const APP_SETTINGS_GET = 'api/admin/settings';
export const APP_SETTING_CREATE = 'api/admin/settings';
export const APP_SETTING_UPDATE = 'api/admin/settings/';
export const APP_SETTING_UPDATE_PROMOTIONAL_BANNER = 'api/admin/settings/promotional-banner-update';
export const APP_SETTING_DELETE = 'api/admin/settings/';
export const APP_SETTING_UPDATE_TIMING = 'api/admin/settings/timing';

export const APP_SETTING_CLEAR_CACHE = 'api/admin/settings/clear-cache';
export const APP_SETTING_DEBUG = 'api/admin/settings/debug';


// storage
export const STORAGE_FULL_URL = `${BASE_API_URL}/storage/`;

// Inactivity Ad
export const GET_INACTIVITY_AD = 'api/admin/inactivity-ad';
export const CREATE_INACTIVITY_AD = 'api/admin/inactivity-ad';
export const UPDATE_INACTIVITY_AD = 'api/admin/inactivity-ad/';
export const DELETE_INACTIVITY_AD = 'api/admin/inactivity-ad/';

// Contact Export
export const GET_CONTACT_LIST = 'api/admin/export-contact';
