<template>
  <header class="header">
    <div
      class="toggle-btns"
      @click="handleSidebarExpand"
    >
      <a id="toggle-sidebar">
        <i class="icon-menu"></i>
      </a>
      <a id="pin-sidebar">
        <i class="icon-menu"></i>
      </a>
    </div>

    <div class="header-items">

      <PrinterStatus />

      <NewOrderSound />

      <!-- Custom search start -->
      <!--<div class="custom-search">-->
      <!--  <input type="text" class="search-query" placeholder="Search here ...">-->
      <!--  <i class="icon-search1"></i>-->
      <!--</div>-->
      <!-- Custom search end -->

      <!-- Header actions start -->
      <ul class="header-actions">
        <li class="dropdown user-settings">
          <a href="#" id="userSettings" data-toggle="dropdown" aria-haspopup="true">
            <UserAvatar
              :name="state.userName"
            />
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userSettings">
            <div class="header-profile-actions">
              <div class="header-user-profile">
                <div class="header-user">
                  <UserAvatar
                    :name="state.userName"
                  />
                </div>
                <h5>{{ state.userName }}</h5>
                <p
                  v-for="group in state.userGroups"
                  :key="group"
                >
                  {{ group }}
                </p>
              </div>
              <a @click="handleLogout">
                <spinner
                  v-if="state.loading"
                  classes="me-1"
                  type="li"
                  size="15"
                />
                <i v-else class="icon-log-out1"></i>
                Sign Out
              </a>
            </div>
          </div>
        </li>
      </ul>
      <!-- Header actions end -->
    </div>
  </header>
</template>

<script>
import UserAvatar from '@/components/Util/UserAvatar';
import { useStore } from 'vuex';
import { computed, reactive, watch } from 'vue';
import Spinner from '@/components/Util/Spinner';
import NewOrderSound from '@/components/Util/NewOrderSound';
import PrinterStatus from '@/components/Util/PrinterStatus';

export default {
  name: 'TopNav',
  components: {
    PrinterStatus,
    NewOrderSound,
    Spinner,
    UserAvatar,
  },
  setup () {
    const store = useStore();
    const sidebarState = store.state.app.layout.sidebar;
    const userState = store.state.user;

    const state = reactive({
      loading: false,
      userName: computed(() => userState.data?.name),
      userGroups: computed(() => userState.data?.aclGroups),
    });

    watch(userState, (nv) => {
      state.loading = nv.loading;
    });

    const handleSidebarExpand = () => {
      store.dispatch('app/setSidebarExpanded', !sidebarState.expanded);
    }

    const handleLogout = () => {
      store.dispatch('user/tryLogOut');
    };

    return {
      state,
      handleSidebarExpand,
      handleLogout
    };
  }
};
</script>
