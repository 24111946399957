<template>
  <div class="d-flex">
    <span class="mx-2 sound-switch-label">Sound</span>
    <SwitchComp
      class="sound-switch"
      :checked="state.soundEnabled"
      enable-text="Enabled"
      disable-text="Disabled"
      @change="handleSoundToggle"
    />

    <SoundComp
      :play="state.playSound"
      :enabled="state.soundEnabled"
      :loop="true"
      :loop-interval="2000"
      :file-path="state.orderSoundFilePath"
    />
  </div>
</template>

<script>
import SoundComp from '@/components/Util/SoundComp';
import SwitchComp from '@/components/Util/SwitchComp';
import { computed, reactive } from 'vue';
import { STORAGE_FULL_URL } from '@/utils/Urls';
import { useStore } from 'vuex';

export default {
  name: 'NewOrderSound',
  components: { SoundComp, SwitchComp },

  setup () {
    const store = useStore();

    const state = reactive({
      soundEnabled: computed(() => store.getters['app/getNewOrderSoundIsEnabled']),
      playSound: computed(() => store.getters['app/getNewOrderSoundPlay']),
      orderSoundFilePath: `${STORAGE_FULL_URL}sounds/order_sound_1.mp3`,
    });

    const handleSoundToggle = (enabled) => store.dispatch('app/setNewOrderSound', enabled);

    return {
      state,
      handleSoundToggle,
    };
  },
}
</script>

<style lang="scss">
@import "../../assets/sass/_variables.scss";
.sound-switch-label{
  text-transform: uppercase;
  color: $title-color;
}
.sound-switch {
  text-transform: uppercase;
  color: $title-color;
}
label.switch input[type=checkbox]:checked + span:before {
  background: $toggle-btn-bg !important;
}
label.switch input[type=checkbox]:checked + span:after {
  background: $toggle-btn-bg-light !important;
}
label.switch input[type=checkbox] + span:before {
  background-color: $sub-title-color !important;
}
label.switch input[type=checkbox] + span:after {
  background-color: $title-color !important;
}
</style>
