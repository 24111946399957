<!--suppress CssInvalidPropertyValue -->
<template>
  <span style="{display: 'none'}" />
</template>

<script>
import { onMounted } from 'vue';
import Echo from '@/Plugins/Echo';
import { useStore } from 'vuex';

export default {
  name: 'WSHandler',

  setup () {
    const store = useStore();
    const orderState = store.state.order;
    const reservationState = store.state.reservation;
    const dashboardState = store.state.dashboard;

    onMounted(() => {

      // listen for new order
      listenForNewOrder();

      // listen for order status update
      listenForOrderStatusUpdate();

      // listen for new reservation
      listenForNewReservation();

      // listen for new Print
      listenForNewPrint();

    });

    //// orders start ////

    const listenForOrderStatusUpdate = () => {

      Echo
        .private('orderCh.order-updated')
        .listen('.order.status-updated', ({ order }) => {

          // update dashboard orders
          store.dispatch('dashboard/updateOrAddOrder', order);

          // update orders
          store.dispatch('order/updateOrAddOrder', order);

        })
      ;
    };

    const listenForNewOrder = () => {

      Echo
        .private('orderCh.new-order')
        .listen('.order.new', data => {
          addNewlyAddedOrder(data.order);
          playNewOrderSound();
          incrementNewOrderCount();
        })
      ;
    };

    function addNewlyAddedOrder (order) {
      // add order in orders
      addNewOrderToOrdersStore(order);

      // add order in dashboard
      addNewOrderToDashboardOrders(order);
    }

    function addNewOrderToOrdersStore (order) {
      const existingOrders = orderState.data.orders || [];

      // add order in orders
      const newOrders = {
        ...orderState.data,
        orders: [
          { ...order },
          ...existingOrders,
        ]
      };

      store.dispatch('order/setData', newOrders);
    }

    function addNewOrderToDashboardOrders (order) {

      // put in pending & incomplete orders
      const existingOrders = dashboardState.data.orders;

      const newDashboardOrders = {
        ...dashboardState.data,
        orders: [
          { ...order },
          ...existingOrders
        ],
      };

      store.dispatch('dashboard/setData', newDashboardOrders);
    }

    function playNewOrderSound () {
      store.dispatch('app/setNewOrderSoundPlay', true);
    }

    function incrementNewOrderCount () {
      store.dispatch('app/incrementNewOrderCount');
    }

    //// orders end ////

    //// reservation start ////

    const listenForNewReservation = () => {

      Echo
        .private('reservationCh.new-reservation')
        .listen('.reservation.new', data => {
          addNewlyAddedReservation(data.reservation);
          incrementNewReservationCount();
        })
      ;
    };

    function addNewlyAddedReservation (reservation) {
      // add reservation in reservations
      updateReservationStore(reservation);

      // add reservation in dashboard
      updateDashboardReservation(reservation);
    }

    function updateReservationStore (reservation) {
      const existingReservations = reservationState.data.reservation || [];

      // add reservation in orders
      const newReservations = {
        ...reservationState.data,
        reservation: [
          { ...reservation },
          ...existingReservations,
        ]
      };

      store.dispatch('reservation/setData', newReservations);
    }

    function updateDashboardReservation (reservation) {
      const existingReservations = dashboardState.data.reservations || [];

      const newDashboardData = {
        ...dashboardState.data,
        reservations: [
          { ...reservation },
          ...existingReservations
        ]
      };

      store.dispatch('dashboard/setData', newDashboardData);
    }

    function incrementNewReservationCount () {
      store.dispatch('app/incrementNewReservationCount');
    }

    //// reservation end ////

    //// print start ////

    const listenForNewPrint = () => {

      Echo
        .private('orderCh.print')
        .listen('.order.print', data => {
          addNewPrint(data);
        })
      ;
    };

    function addNewPrint (printData) {
      store.dispatch('print/addPrint', printData);
    }

    //// print end ////

  }
};
</script>

