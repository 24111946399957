<template>
  <ol
    v-if="state.segments"
    class="breadcrumb"
  >
    <li class="breadcrumb-item">
      <a :href="state.rootPath">{{ state.appName }}</a>
    </li>
    <li
      v-for="(segment, i) in state.segments"
      class="breadcrumb-item text-capitalize"
      :key="`breadcrumb-${i}`"
    >
      {{ segment }}
    </li>
  </ol>
</template>

<script>
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'TopBreadCrumb',
  props: {
    path: {
      type: String,
      default: ''
    }
  },

  setup (props) {

    const route = useRoute();

    const state = reactive({
      appName: 'Order Online',
      rootPath: '/',
      pathSeparator: '/',
      segments: computed(() => {
        return props.path
          ? props.path
          : route.path.split(state.pathSeparator).filter(itm => itm);
      })
    });


    return {
      state
    };

  },
};
</script>
<style lang="scss">
.breadcrumb .breadcrumb-item{
  text-transform: uppercase !important;
}
</style>
