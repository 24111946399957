import defaultState from '../default/state';
import defaultMutations from '../default/mutations';
import actions from './actions';

export default {
  namespaced: true,

  state: () => ({
    ...defaultState,
  }),

  getters: {
    offer: (state) => state.data.offers || [],

    offerTypes: (state) => {

      if (!state.data.offerTypes) return [];

      return state.data.offerTypes.map((item) => ({
        label: item,
        id: item
      }));

    },

    orderTypes: (state) => {

      if (!state.data.orderTypes) return [];

      return state.data.orderTypes.map((item) => ({
        id: item,
        label: item
      }))
    },

    offerAvailability: (state) => {

      if (!state.data.offerAvailability) return [];

      return state.data.offerAvailability.map((item) => ({
        id: item,
        label: item
      }))
    }

  },

  mutations: {
    ...defaultMutations,
    setOffer: (state, payload) => state.data.offers = payload
  },

  actions: {
    ...actions,
  }
};
