import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import appMixins from '@/Mixins/app';
import '@/Plugins/index';

import VueAnimXyz from '@animxyz/vue3';
import '@animxyz/core';

import ElementPlus from 'element-plus';
import '@/assets/sass/app.scss';

import ConfirmationService from 'primevue/confirmationservice';
import PrimeVue from 'primevue/config';

import { abilitiesPlugin } from '@casl/vue';
import ability from '@/config/permissions/ability';

import VuePlyr from 'vue-plyr';

const app = createApp(App);

app.use(store);

app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true
});
app.mixin(appMixins);
app.use(VueAnimXyz);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ElementPlus);
app.use(router);
app.use(VuePlyr, {
  plyr: {}
});

app.mount('#app');
