import defaultState from '../default/state';
import defaultMutations from '../default/mutations';
import actions from './actions';

export default {
  namespaced: true,

  state: () => ({
    ...defaultState,
    status: 'disconnected', // connecting,connected,disconnected,failed
    autoReconnect: true,
  }),

  getters: {
    getAll: (state) => state.data || [],

    getOldest: (state) => {
      return state.data.at(0);
    },

    getNewest: (state) => {
      return state.data.at(-1);
    },

    getStatus: (state) => {
      return state.status;
    },

    getAutoReconnect: (state) => state.autoReconnect,
  },

  mutations: {
    ...defaultMutations,
    addPrintData: (state, payload) => state.data = [...state.data, payload],
    setStatus: (state, payload) => state.status = payload,
    setReconnect: (state, payload) => state.autoReconnect = payload,
  },

  actions: {
    ...actions,
  }
};
