/* eslint-disable quotes */
export default class Printer {

  static STATUS_CONNECTING = 'connecting';
  static STATUS_CONNECTED = 'connected';
  static STATUS_DISCONNECTED = 'disconnected';
  static STATUS_FAILED = 'failed';

  static OPTIONS = {
    FONT_A: 'font_a',
    FONT_B: 'font_b',
    FONT_C: 'font_c',
    FONT_D: 'font_d',
    FONT_E: 'font_e',
    FONT_SPECIAL_A: 'special_a',
    FONT_SPECIAL_B: 'special_b',
    ALIGN_LEFT: 'left',
    ALIGN_CENTER: 'center',
    ALIGN_RIGHT: 'right',
    COLOR_NONE: 'none',
    COLOR_1: 'color_1',
    COLOR_2: 'color_2',
    COLOR_3: 'color_3',
    COLOR_4: 'color_4',
    FEED_PEELING: 'peeling',
    FEED_CUTTING: 'cutting',
    FEED_CURRENT_TOF: 'current_tof',
    FEED_NEXT_TOF: 'next_tof',
    MODE_MONO: 'mono',
    MODE_GRAY16: 'gray16',
    BARCODE_UPC_A: 'upc_a',
    BARCODE_UPC_E: 'upc_e',
    BARCODE_EAN13: 'ean13',
    BARCODE_JAN13: 'jan13',
    BARCODE_EAN8: 'ean8',
    BARCODE_JAN8: 'jan8',
    BARCODE_CODE39: 'code39',
    BARCODE_ITF: 'itf',
    BARCODE_CODABAR: 'codabar',
    BARCODE_CODE93: 'code93',
    BARCODE_CODE128: 'code128',
    BARCODE_GS1_128: 'gs1_128',
    BARCODE_GS1_DATABAR_OMNIDIRECTIONAL: 'gs1_databar_omnidirectional',
    BARCODE_GS1_DATABAR_TRUNCATED: 'gs1_databar_truncated',
    BARCODE_GS1_DATABAR_LIMITED: 'gs1_databar_limited',
    BARCODE_GS1_DATABAR_EXPANDED: 'gs1_databar_expanded',
    BARCODE_CODE128_AUTO: 'code128_auto',
    HRI_NONE: 'none',
    HRI_ABOVE: 'above',
    HRI_BELOW: 'below',
    HRI_BOTH: 'both',
    SYMBOL_PDF417_STANDARD: 'pdf417_standard',
    SYMBOL_PDF417_TRUNCATED: 'pdf417_truncated',
    SYMBOL_QRCODE_MODEL_1: 'qrcode_model_1',
    SYMBOL_QRCODE_MODEL_2: 'qrcode_model_2',
    SYMBOL_QRCODE_MICRO: 'qrcode_micro',
    SYMBOL_MAXICODE_MODE_2: 'maxicode_mode_2',
    SYMBOL_MAXICODE_MODE_3: 'maxicode_mode_3',
    SYMBOL_MAXICODE_MODE_4: 'maxicode_mode_4',
    SYMBOL_MAXICODE_MODE_5: 'maxicode_mode_5',
    SYMBOL_MAXICODE_MODE_6: 'maxicode_mode_6',
    SYMBOL_GS1_DATABAR_STACKED: 'gs1_databar_stacked',
    SYMBOL_GS1_DATABAR_STACKED_OMNIDIRECTIONAL: 'gs1_databar_stacked_omnidirectional',
    SYMBOL_GS1_DATABAR_EXPANDED_STACKED: 'gs1_databar_expanded_stacked',
    SYMBOL_AZTECCODE_FULLRANGE: 'azteccode_fullrange',
    SYMBOL_AZTECCODE_COMPACT: 'azteccode_compact',
    SYMBOL_DATAMATRIX_SQUARE: 'datamatrix_square',
    SYMBOL_DATAMATRIX_RECTANGLE_8: 'datamatrix_rectangle_8',
    SYMBOL_DATAMATRIX_RECTANGLE_12: 'datamatrix_rectangle_12',
    SYMBOL_DATAMATRIX_RECTANGLE_16: 'datamatrix_rectangle_16',
    LEVEL_0: 'level_0',
    LEVEL_1: 'level_1',
    LEVEL_2: 'level_2',
    LEVEL_3: 'level_3',
    LEVEL_4: 'level_4',
    LEVEL_5: 'level_5',
    LEVEL_6: 'level_6',
    LEVEL_7: 'level_7',
    LEVEL_8: 'level_8',
    LEVEL_L: 'level_l',
    LEVEL_M: 'level_m',
    LEVEL_Q: 'level_q',
    LEVEL_H: 'level_h',
    LEVEL_DEFAULT: 'default',
    LINE_THIN: 'thin',
    LINE_MEDIUM: 'medium',
    LINE_THICK: 'thick',
    LINE_THIN_DOUBLE: 'thin_double',
    LINE_MEDIUM_DOUBLE: 'medium_double',
    LINE_THICK_DOUBLE: 'thick_double',
    DIRECTION_LEFT_TO_RIGHT: 'left_to_right',
    DIRECTION_BOTTOM_TO_TOP: 'bottom_to_top',
    DIRECTION_RIGHT_TO_LEFT: 'right_to_left',
    DIRECTION_TOP_TO_BOTTOM: 'top_to_bottom',
    CUT_NO_FEED: 'no_feed',
    CUT_FEED: 'feed',
    CUT_RESERVE: 'reserve',
    DRAWER_1: 'drawer_1',
    DRAWER_2: 'drawer_2',
    PULSE_100: 'pulse_100',
    PULSE_200: 'pulse_200',
    PULSE_300: 'pulse_300',
    PULSE_400: 'pulse_400',
    PULSE_500: 'pulse_500',
    PATTERN_NONE: 'none',
    PATTERN_0: 'pattern_0',
    PATTERN_1: 'pattern_1',
    PATTERN_2: 'pattern_2',
    PATTERN_3: 'pattern_3',
    PATTERN_4: 'pattern_4',
    PATTERN_5: 'pattern_5',
    PATTERN_6: 'pattern_6',
    PATTERN_7: 'pattern_7',
    PATTERN_8: 'pattern_8',
    PATTERN_9: 'pattern_9',
    PATTERN_10: 'pattern_10',
    PATTERN_A: 'pattern_a',
    PATTERN_B: 'pattern_b',
    PATTERN_C: 'pattern_c',
    PATTERN_D: 'pattern_d',
    PATTERN_E: 'pattern_e',
    PATTERN_ERROR: 'error',
    PATTERN_PAPER_END: 'paper_end',
    LAYOUT_RECEIPT: 'receipt',
    LAYOUT_RECEIPT_BM: 'receipt_bm',
    LAYOUT_LABEL: 'label',
    LAYOUT_LABEL_BM: 'label_bm',
    HALFTONE_DITHER: 0,
    HALFTONE_ERROR_DIFFUSION: 1,
    HALFTONE_THRESHOLD: undefined,
    TEXT_FEED_TAB: "\t",
    TEXT_FEED_LINE: "\n",
  };

  static CONNECTION_STATUSES = {
    OK: 'Connection via HTTP communication has been successful when the ePOS-Print option is enabled or not enabled.',
    SSL_CONNECT_OK: 'Connection via SSL/TLS communication has been successful when the ePOS-Print option is not enabled.',
    ERROR_TIMEOUT: 'Timeout occurred.',
    ERROR_PARAMETER: 'A parameter error occurred.'
  };

  static PRINT_ERRORS = {
    EDSP_NOT_FOUND: 'The device was not found.',
    EDSP_NOT_OPEN: 'Failed to open the device.',
    EDSP_INVALID_WINDOW: 'An unregistered window was specified.',
    EDSP_UNRECOVERABLE: 'Unrecoverable error occurred on the connected printer.',
    EDSP_CUTTER: 'Auto cutter error occurred on the connected printer.',
    EDSP_MECHANICAL: 'Mechanical error occurred on the connected printer.',
    EDSP_COVER_OPEN: 'Cover of the connected printer is open.',
    EDSP_REC_EMPTY: 'Connected printer has run out of paper.',
    EDSP_AUTOMATICAL: 'Automatic recovery error occurred on the connected printer.',
    EDSP_SCHEMA_ERROR: 'The XML structure is not correct.',
    EX_BADPORT: 'An internal communication error with the device occurred.',
    EX_TIMEOUT: 'A timeout error occurred during communication with the device.',
    EX_INVALID_VALUE: 'Invalid parameter was detected.',
    TooManyRequests: 'The number of display data sent to the display has exceeded the allowable limit.',
  };

}
