<template>
  {{ null }}
</template>

<script>
import { computed, defineComponent, onMounted, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import PrintProcessor from '@/utils/Printer/PrintProcessor';
import Printer from '@/utils/Printer';
import { notify } from '@/Mixins/appHelper';
import Order from '@/Services/Order';

const printerState = {
  epson: null,
  device: null,
  printer: null,
};

export default defineComponent({
  name: 'PrintHandler',

  setup () {

    const store = useStore();

    // get printer address info
    const networkData = computed(() => {
      const printerSettings = store.getters['setting/getSettings']([
        'printer_ip',
        'printer_id',
        'printer_port',
      ]);

      return {
        ip: printerSettings.printer_ip,
        port: printerSettings.printer_port,
        id: printerSettings.printer_id
      };
    });

    const printerAutoConnect = computed(() => store.getters['print/getAutoReconnect']);

    const state = reactive({
      orderId: null,
    });

    // get new printer when info available
    watch(() => networkData.value, async (nv) => {
      // state.printer = await Printer.getPrinter({
      //   ...nv,
      //   onConnecting: handleConnecting,
      //   onConnected: handleConnected,
      //   onFailed: handleFailed
      // });
    })

    // set printer status

    function setConnecting () {
      store.dispatch('print/setPrinterStatus', Printer.STATUS_CONNECTING);
    }

    function setConnected () {
      store.dispatch('print/setPrinterStatus', Printer.STATUS_CONNECTED);
    }

    function setDisconnected () {
      store.dispatch('print/setPrinterStatus', Printer.STATUS_DISCONNECTED);
    }

    function setFailed () {
      store.dispatch('print/setPrinterStatus', Printer.STATUS_FAILED);
    }

    function handleOnline () {
      setConnected();
    }

    function handleOffline () {
      setDisconnected();
    }

    // update order print status
    async function handlePrintStatus (result) {
      const orderId = state.orderId;

      try {

        await Order.updatePrintStatus(orderId, result);

      } catch (e) {
        notify(`Print status update error for order: ${orderId}`, 'error', 5000);
      }
    }



    onMounted(async () => {

      createConnectObject();

    });

    // try to connect with printer when ip available
    watch(networkData, async (nv, pv) => {

      // run only when ip available
      if (pv.ip !== undefined) return;

      await getDevice();

    });


    function createConnectObject () {

      if (printerState.device !== null) return;

      printerState.epson = window.epson;

      // eslint-disable-next-line new-cap
      printerState.device = new printerState.epson.ePOSDevice();
    }

    async function getDevice () {

      const isConnected = printerState.device?.isConnected();

      if (isConnected) return;

      setConnecting();

      try {

        const printer = await createConnection();

        // start monitoring, without this no events cb called
        printer.startMonitor();

        printerState.printer = printer;

        // register event listeners
        registerListeners();

        return true;

      } catch (e) {

        setFailed();
        notify('Printer connection failed, please ensure printer is online and has paper', 'error', 20000);

      }

      return false;
    }

    function createConnection () {

      return new Promise((resolve, reject) => {

        const { ip, port, id } = networkData.value;
        const device = printerState.device;

        // connect to real printer
        device.connect(ip, port, (data) => {

          const statusOk = ((data === device.RESULT_OK) || (data === device.SSL_CONNECT_OK));

          // if connection unsuccessful then throw error
          if (!statusOk) reject(data);

          // device info
          const dId = id;
          const dType = device.DEVICE_TYPE_PRINTER;
          const dOptions = {
            crypto: false,
            buffer: false
          };

          device.createDevice(dId, dType, dOptions, (deviceObj, retCode) => {

            if (retCode !== device.RESULT_OK) reject(retCode);

            // return connected device
            resolve(deviceObj);

          });

        }, { eposprint: true });

      });

    }

    function registerListeners () {

      const { printer } = printerState;

      printer.ononline = handleOnline;
      printer.onoffline = handleOffline;
      printer.onreceive = handlePrintStatus;

      // printer.onstatuschange = (d) => console.log('status changed', d);

      printer.onpapernearend = () => notify('Printer paper almost finished!', 'warning', 5000);
      printer.onpaperend = () => notify('Printer paper ended! Please insert paper.', 'error', 20000);
      printer.oncoveropen = () => notify('Please close printer cover before printing', 'error');

    }


    watch(() => printerAutoConnect.value, (nv) => {

      // disconnect printer if auto connect turned off
      if (!nv) disconnect();

    });

    // if need to disconnect
    function disconnect () {

      // try not to disconnect if already disconnected
      if (!printerState.device.isConnected()) return;

      // stop monitoring
      printerState.printer.stopMonitor();

      // delete printer
      printerState.device.deleteDevice(printerState.printer, () => {

        // terminate communication with printer
        printerState.device.disconnect();
      });
    }


    const printJobs = computed(() => store.getters['print/getAll']);

    watch(printJobs, (nv) => {
      // when no data available do nothing
      if (!nv.length) return;

      // print new first
      const order = store.getters['print/getNewest'];

      // store order id
      state.orderId = order.orderId;

      startPrinting(order.printData);
    });

    async function startPrinting (printData) {

      const isConnected = printerState.device.isConnected();

      // if disconnected then connect before printing
      if (!isConnected) {

        //don't auto connect if disabled
        if (!printerAutoConnect.value) {
          notify('Printing disabled! Enable it to print from here.', 'error', 5000);
          return;
        }

        const deviceStatus = await getDevice();
        if (!deviceStatus) return;
      }

      const printPayload = printData;
      // const printPayload = mockPrintData();

      // start printing
      const process = new PrintProcessor(printerState.printer, printPayload);
      process.printNow();

    }

    function mockPrintData () {
      return [
        { t: 't', f: '2,3,2,0,c,1,8', c: 'Order Online' },
        { t: 't', f: '2,2,2,0,c,1,8', c: 'Collection' },
        { t: 't', f: '2,2,2,0,l,1,8', c: 'Total                      81.54' },
        // { t: 't', f: '2,1,2,0,l,1,30', c: 'In publishing and graphic design document design document design document' },
      ];
    }

  }
});
</script>

