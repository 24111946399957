import Printer from '@/utils/Printer/index';

export default class PrintFormatter {

  _options = null;
  _printer = null;

  constructor (printer) {

    this._options = Printer.OPTIONS;
    this._printer = printer;

    //enable text smooth by default
    this.setTextSmooth();

  }

  addText (text, addNewLine = true) {
    const prntText = addNewLine
      ? text + this._options.TEXT_FEED_LINE
      : text
    ;
    this._printer.addText(prntText);
    return this;
  }

  setFont (font) {

    const fonts = [
      this._options.FONT_A,
      this._options.FONT_B,
      this._options.FONT_C,
      this._options.FONT_SPECIAL_A,
      this._options.FONT_SPECIAL_B,
    ];

    const finalFont = fonts[font];

    this._printer.addTextFont(finalFont);
    return this;
  }

  setSize (wSize, hSize) {

    if (wSize < 0 || wSize > 8 || hSize < 0 || hSize > 8) {
      throw new Error(`Width & Height size must be between 0-8 provided, width: ${wSize} height: ${hSize} `);
    }

    this._printer.addTextSize(wSize, hSize);
    return this;
  }

  setLineHeight (lineHeightInDots) {

    if (lineHeightInDots < 0 || lineHeightInDots > 255) {
      throw new Error(`Line height must be between 0 - 255, provided: ${lineHeightInDots}`);
    }

    this._printer.addTextLineSpace(lineHeightInDots);
    return this;
  }

  setBold (makeBold = false) {
    this._printer.addTextStyle(false, false, makeBold);
    return this;
  }

  setAlign (align = this._options.ALIGN_LEFT) {

    const options = {
      l: this._options.ALIGN_LEFT,
      c: this._options.ALIGN_CENTER,
      r: this._options.ALIGN_RIGHT,
    };

    this._printer.addTextAlign(options[align]);
    return this;
  }

  setTextSmooth (smooth = true) {
    this._printer.addTextSmooth(smooth);
    return this;
  }

  setEmptyLines (noOfLines = 1) {

    const lines = parseInt(noOfLines);

    if (lines < 0 || lines > 255) {
      throw new Error(`'noOfLines' can be 0 - 255, provide: ${lines}`);
    }

    this._printer.addFeedLine(lines);
    return this;
  }

  setCut () {
    this.setEmptyLines();
    this._printer.addCut(this._options.CUT_FEED);

    return this;
  }

  startPrint () {
    this._printer.send();
  }

}
