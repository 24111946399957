import defaultActions from '../default/actions';
import { SET_DATA } from '@/store/modules/default/types';

export default {
  ...defaultActions,

  addPrint: ({ commit }, payload) => {

    // override items to store
    commit('addPrintData', payload);
  },

  deletePrint: ({ getters, commit }, orderId) => {

    const existingPrints = getters.getAll();

    const prints = existingPrints.filter((print) => print.orderId !== orderId);
    commit(SET_DATA, prints);
  },

  setPrinterStatus: ({ commit }, status) => {

    // override items to store
    commit('setStatus', status);
  },

  setAutoReconnect: ({ commit }, autoReconnect = true) => commit('setReconnect', autoReconnect),
};
